import React from "react";
import "./style.scss";

export default function MeasurementAndAnalysis() {
  return (
    <div className="container is-fullhd">
      <section className="section is-small auryris-padding" id="mesures-et-analyses">
        <div className="columns">
          <div className="column is-half">
            <section className="hero is-halfheight is-success banner-measurement-and-analysis hero-measurement-and-analysis">
              <div className="hero-body is-justify-content-center is-centered">
              </div>
            </section>
          </div>
          <div className="column">
            <section className="hero is-halfheight hero-industrial-measurement-and-analysis-content">
              <div className="hero-body is-justify-content-center is-centered">
                <div className="content content-measurement-and-analysis has-background-light">
                  <h2
                    className="title is-size-5-mobile is-size-4 has-text-grey-light has-text-left has-text-weight-bold is-family-sans-serif is-capitalized"
                    title="Mesures & Analyses"
                  >
                    Mesures & Analyses
                  </h2>
                  <div className="content is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                    Basée sur une forte expérience en acoustique et mécanique vibratoire dans les domaines de l&apos;automobile et de l&apos;aéronautique, elle nous permet de vous proposer :
                    <ul>
                      <li>
                        Le diagnostic de défaillance sur des systèmes en
                        fonctionnement
                      </li>
                      <li>
                        L&apos;optimisation de fonctionnement de systèmes en mouvement : équilibrage, maîtrise de la dynamique de flexion et de torsion, réduction de défaut d&apos;alignement...
                      </li>
                      <li>
                        L&apos;analyse prédictive de réponse à une sollicitation vibratoire : analyse modale expérimentale et/ou par simulation éléments finis
                      </li>
                      <li>
                        Le recalage de modèles numériques par mesures expérimentales et/ou opérationnelles
                      </li>
                      <li>
                        La surveillance automatisée et la maintenance prédictive de systèmes en fonctionnement
                      </li>
                    </ul>
                  </div>

                  <p className="is-size-7 has-text-left has-text-weight-semibold is-family-sans-serif mt-5">
                    <div className="buttons">
                      <a
                        href="#nous-contacter"
                        className="button is-light is-outlined"
                      >
                        Contacter un expert
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}
