import React from "react";

import Ausyris from "./Ausyris/index";
import OurExpertise from "./OurExpertise";
import DiscussWithUs from "./DiscussWithUs";
import MechanicalAndHydraulic from "./MechanicalAndHydraulic";
import MeasurementAndAnalysis from "./MeasurementAndAnalysis";
import ElectronicsAndElectrotechnics from "./ElectronicsAndElectrotechnics";
import IndustrialAutomationAndIT from "./IndustrialAutomationAndIT";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";

export default function Home() {
  return (
    <>
      <Ausyris />
      <OurExpertise />
      <DiscussWithUs />

      <MechanicalAndHydraulic />
      <IndustrialAutomationAndIT />
      <ElectronicsAndElectrotechnics />
      <MeasurementAndAnalysis />

      <AboutUs />
      <ContactUs />
    </>
  );
}
