import React from "react";
import { Outlet } from "react-router-dom";
import HeroLogo from "./../Home/HeroLogo";

import Nav from "./Nav";

export default function Profile() {
  return (
    <div className="columns is-multiline">
      <div className="column is-full"><Nav /></div>
      <div className="column is-half has-background-link"><HeroLogo /></div>
      <div className="column"><Outlet /></div>
    </div>
  );
}
