import React from "react";
import VCard from "vcard-creator";
import {
  FaPaperPlane,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaBriefcase,
  FaGlobe,
  FaUserPlus,
} from "react-icons/fa";

import logo from "./../../assets/ausyris-logo-original.png";

export default function Card() {
  const gbd = new VCard();
  gbd
    .addPhoto(logo, "JPEG")
    .addName("BIVARD", "Gardy", "", "", "")
    .addCompany("AUSYRIS")
    .addJobtitle("Président")
    .addRole("Responsable Financier")
    .addEmail("gardy.bivard@ausyris.com")
    .addPhoneNumber("+33621914052", "PREF;WORK")
    .addPhoneNumber("+33621914052", "WORK")
    .addAddress(
      "",
      "",
      "57 rue Ernest Renan",
      "Nanterre",
      "",
      "92000",
      "France"
    )
    .addURL("https://www.ausyris.com");

  const gbdFile = new Blob([gbd.toString()], {
    type: "text/vcard;charset=ISO-8859-1",
  });

  return (
    <div className="">
      <div className="">
        <div className="media my-6">
          <div className="media-content">
            <p className="title is-4">Gardy BIVARD</p>
            <p className="subtitle is-6">Président</p>
          </div>
        </div>

        <div className="content pb-3">
          <p className="subtitle">Responsable Financier</p>
        </div>
      </div>

      <footer className="card-footer">
        <a
          href="tel:+33621914052"
          className="card-footer-item is-uppercase"
        >
          <span className="icon">
            <FaPhoneAlt />
          </span>
          <span className="">Appeler</span>
        </a>

        <a
          className="card-footer-item is-uppercase"
          id="email-gardy-bivard"
          href="mailto:gardy.bivard@ausyris.com"
        >
          <span className="icon">
            <FaPaperPlane />
          </span>
          <span className="">E-mail</span>
        </a>

        <a
          href="https://goo.gl/maps/NZjgbqzwUYiFqS3h8"
          className="card-footer-item is-uppercase"
          target="_balnk"
          rel="noreferrer"
        >
          <span className="icon">
            <FaMapMarkerAlt />
          </span>
          <span className="">Itinéraire</span>
        </a>
      </footer>

      <div className="card-content has-background-white">
        <div className="media is-vcentered">
          <div className="media-left is-vcentered">
            <span className="icon is-size-2">
              <FaPhoneAlt />
            </span>
          </div>
          <div className="media-content">
            <p className="title is-6 has-text-left has-text-grey-dark">
              <a
                className="has-text-grey-dark"
                id="e-amil"
                href="tel:+33621914052"
              >
                +33 (0)6 21 91 40 52
              </a>
            </p>
            <p className="subtitle is-6 has-text-left has-text-grey-light">
              Mobile
            </p>
          </div>
        </div>
        <div className="media is-vcentered">
          <div className="media-left is-vcentered">
            <span className="icon is-size-2">
              <FaEnvelope />
            </span>
          </div>
          <div className="media-content">
            <p className="title is-6 has-text-left has-text-grey-dark">
              <a
                className="has-text-grey-dark"
                id="e-amil"
                href="mailto:gardy.bivard@ausyris.com"
              >
                gardy.bivard@ausyris.com
              </a>
            </p>
            <p className="subtitle is-6 has-text-left has-text-grey-light">
              E-mail
            </p>
          </div>
        </div>
        <div className="media is-vcentered">
          <div className="media-left is-vcentered">
            <span className="icon is-size-2">
              <FaBriefcase />
            </span>
          </div>
          <div className="media-content">
            <p className="title is-6 has-text-left has-text-grey-dark">
              Responsable Financier
            </p>
            <p className="subtitle is-6 has-text-left has-text-grey-light">
              Président
            </p>
          </div>
        </div>
        <div className="media is-vcentered">
          <div className="media-left is-vcentered">
            <span className="icon is-size-2">
              <FaMapMarkerAlt />
            </span>
          </div>
          <div className="media-content">
            <p className="title is-6 has-text-left has-text-grey-dark">
              57 rue Ernest Renan Nanterre, IDF 92000
            </p>
            <p className="subtitle is-6 has-text-left has-text-grey-light">
              France
            </p>

            <p className="subtitle is-6 has-text-left">
              <a
                href="https://goo.gl/maps/NZjgbqzwUYiFqS3h8"
                className="card-footer-item is-uppercase jaune-citron-color has-text-left"
                target="_balnk"
                rel="noreferrer"
              >
                <span className="is-uppercase jaune-citron-color has-text-left">
                  Afficher sur la carte
                </span>
              </a>
            </p>
          </div>
        </div>
        <div className="media is-vcentered">
          <div className="media-left is-vcentered">
            <span className="icon is-size-2">
              <FaGlobe />
            </span>
          </div>
          <div className="media-content">
            <p className="title is-6 has-text-left has-text-grey-dark">
              <a
                className="has-text-grey-dark"
                id="e-amil"
                href="https://www.ausyris.com"
              >
                www.ausyris.com
              </a>
            </p>
            <p className="subtitle is-6 has-text-left has-text-grey-light">
              Site web
            </p>
          </div>
        </div>
        <div className="media is-vcentered is-centered is-vcentered hast-text-centered">
          <div className="media-content is-centered is-vcentered hast-text-centered">
            <div className="is-centered is-vcentered hast-text-centered">
              <a
                className="button is-uppercase has-text-white-bis bg-jaune-citron-color"
                id="download"
                download="Gardy BIVARD.vcf"
                href={URL.createObjectURL(gbdFile)}
              >
                <span className="icon">
                  <FaUserPlus />
                </span>
                <span className="is-size-6 uppercase has-text-white-bis">
                  Enregistrer
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
