import React from "react";
import imageBan from "./../../assets/ausyris-logo.png";

export default function HeroLogo() {
  return (
    <section className="hero is-fullheight is-success">
      <div className="hero-body is-justify-content-center is-centered">
        <figure className="is-justify-content-center is-centered">
          <img src={imageBan} alt="AUSYRIS" className="" />
        </figure>
      </div>
    </section>
  );
}
