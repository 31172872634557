import React from "react";
import { NavLink } from "react-router-dom";

export default function Nav() {
  return (
    <nav className="tabs is-centered is-small is-toggle">
      <ul>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "activeLink" : "")}
            to="/contacts/card"
          >
            Gardy BIVARD
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "activeLink" : "")}
            to="/contacts/gbd"
          >
            Gardy BIVARD
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "activeLink" : "")}
            to="/contacts/jpx"
          >
            Jérémy PEJOUX
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "activeLink" : "")}
            to="/contacts/fct"
          >
            Félix CHAMBARET
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
