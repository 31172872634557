import React from "react";
import "./style.scss";

export default function ElectronicsAndElectrotechnics() {
  return (
    <div className="container is-fullhd">
      <section className="section is-small auryris-padding" id="electronique-et-electrotechnique">
        <div className="columns">
          <div className="column is-half">
            <section className="hero is-halfheight hero-electronics-and-electrotechnics-content">
              <div className="hero-body is-justify-content-center is-centered">
                <div className="content content-electronics-and-electrotechnics has-background-light">
                  <h2
                    className="title is-size-5-mobile is-size-4 has-text-grey-light has-text-left has-text-weight-bold is-family-sans-serif is-capitalized"
                    title="électronique & électrotechnique"
                  >
                    électronique & <br />
                    électrotechnique
                  </h2>
                  <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                    Nous ne ménageons aucun détail dans ces domaines techniques
                    essentiels. Chaque élément est rigoureusement ajusté pour
                    des performances optimales, en mettant l&apos;accent sur l&apos;efficacité énergétique et la sécurité.
                  </p>

                  <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                    Dans le respect de la Directive Machine et à partir de l&apos;analyse des risques électriques, pneumatiques,
                    hydrauliques et mécaniques, nous élaborons des études
                    Sistema. Cette analyse de risques permet de définir les
                    niveaux de sécurité et les équipements les plus adaptés à la
                    machine et à son utilisation. Pour des besoins spécifiques,
                    notre équipe est en mesure de concevoir des cartes
                    électroniques d&apos;acquisition et de génération de signaux
                    électriques.
                  </p>

                  <p className="is-size-7 has-text-left has-text-weight-semibold is-family-sans-serif mt-5">
                    <div className="buttons">
                      <a
                        href="#nous-contacter"
                        className="button is-light is-outlined"
                      >
                        Contacter un expert
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="column">
            <section className="hero is-halfheight is-success banner-electronics-and-electrotechnics hero-electronics-and-electrotechnics">
              <div className="hero-body is-justify-content-center is-centered">
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}
