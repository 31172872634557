import "bulma/css/bulma.css";
import "./app.scss";
import React from "react";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Navigation from "./components/Navigation";
import Contact from "./components/Contact/ContactMap";
import Home from "./components/Home/Home";
import Card from "./components/Profiles/Card";
import Gbd from "./components/Profiles/Gbd";
import Jpx from "./components/Profiles/Jpx";
import Fct from "./components/Profiles/Fct";
import Profile from "./components/Profiles/Profile";
import Footer from "./components/Footer";

import imageLogo from "./assets/image002.png";
// import imageLogo from "!file-loader!./assets/ausyris-logo.svg";
import imageLogoBlanc from "./assets/AUSYRIS.png";

function App() {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    const $navbar = document.querySelector(".navbar");
    const $navbarBrand = document.querySelector(".navbar-brand");
    const $navbarMenu = document.querySelector(".navbar-menu");

    if (scrollTop >= 200) {
      $navbar.classList.add("sticky");
      $navbarMenu.classList.add("sticky");
      $navbarBrand.classList.add("sticky");
      document.getElementById("ausyris-logo").src = imageLogoBlanc;
    } else {
      $navbar.classList.remove("sticky");
      $navbarMenu.classList.remove("sticky");
      $navbarBrand.classList.remove("sticky");
      document.getElementById("ausyris-logo").src = imageLogo;
    }
  });

  return (
    <div className="App"
      id="AUSYRIS">
      <section className="hero is-primary is-halfheight is-from-top banner-home">
        <Navigation />
        <div className="hero-body"></div>
      </section>
      <div className="body-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contacts" element={<Profile />}>
            <Route path="/contacts/card" element={<Card />} />
            <Route path="/contacts/gbd" element={<Gbd />} />
            <Route path="/contacts/jpx" element={<Jpx />} />
            <Route path="/contacts/fct" element={<Fct />} />
          </Route>
          <Route path="/contact/card" element={<Card />} />
          <Route path="/contact/gbd" element={<Gbd />} />
          <Route path="/contact/jpx" element={<Jpx />} />
          <Route path="/contact/fct" element={<Fct />} />
          <Route path="/gbd" element={<Gbd />} />
          <Route path="/jpx" element={<Jpx />} />
          <Route path="/fct" element={<Fct />} />
          <Route path="/gbd/*" element={<Gbd />} />
          <Route path="/jpx/*" element={<Jpx />} />
          <Route path="/fct/*" element={<Fct />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/*" element={<Home />} />
          <Route path="/contacts/*" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
