import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import imageLogo from "./../../assets/image002.png";
// import imageLogo from "!file-loader!./../../assets/ausyris-logo.svg";


import "./style.scss";

export default function index() {

  useEffect(() => {
    try {
      const $navbarBurger = document.querySelector(".navbar-burger");
      const $navbarMenu = document.querySelector(".navbar-menu");
      const $navbarItems = document.querySelectorAll(".navbar-item");

      $navbarBurger.addEventListener("click", (event) => {
        console.log(event);

        $navbarBurger.classList.toggle("is-active");
        $navbarMenu.classList.toggle("is-active");
      });
      $navbarItems.forEach((el) => {
        el.addEventListener("click", (event) => {
          console.log(event);

          $navbarBurger.classList.toggle("is-active");
          $navbarMenu.classList.toggle("is-active");
        });
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <>
      <nav
        className="navbar is-fixed-top is-transparent"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item is-primary">
            <img src={imageLogo} alt="AUSYRIS" id="ausyris-logo" className="nav-brand-img" />
          </Link>
          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-end is-expanded is-tab">
            {/* <a href="#AUSYRIS" className="navbar-item">
              Accueil
            </a>
            <a href="#notre-expertise" className="navbar-item">
              Notre expertise
            </a>
            <a href="#qui-sommes-nous" className="navbar-item">
              A propos
            </a> */}

            <div className="navbar-item">

              <a href="#AUSYRIS" className="button is-primary is-outlined-hover">
                Accueil
              </a>

            </div>
            <div className="navbar-item">

              <a href="#notre-expertise" className="button is-primary is-outlined-hover">
                Notre expertise
              </a>

            </div>
            <div className="navbar-item">

              <a href="#qui-sommes-nous" className="button is-primary is-outlined-hover">
                A propos
              </a>

            </div>
            <div className="navbar-item">

              <a href="#nous-contacter" className="button is-primary is-outlined-hover">
                Nous contacter
              </a>

            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
