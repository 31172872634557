import React from "react";
import "./style.scss";

export default function MechanicalAndHydraulic() {
  return (

    <div className="container is-fullhd">
      <section className="section is-small auryris-padding" id="mecanique-et-hydraulique">
        <div className="columns">
          <div className="column is-half">
            <section className="hero is-halfheight hero-mechanical-and-hydraulic-content">
              <div className="hero-body is-justify-content-center is-centered">
                <div className="content content-mechanical-and-hydraulic has-background-light">
                  <h2
                    className="title is-size-5-mobile is-size-4 has-text-grey-light has-text-left has-text-weight-bold is-family-sans-serif is-capitalized"
                    title="Mécanique, Pneumatique & Hydraulique"
                  >
                    Mécanique, Pneumatique & Hydraulique
                  </h2>
                  <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                    Notre équipe d&apos;experts fusionne habilement les
                    compétences en mécanique, pneumatique et hydraulique pour
                    des solutions optimales, adaptées à chaque projet. Nous
                    privilégions toujours une approche pragmatique et efficace
                    de ces éléments, justement dimensionnés face à votre besoin
                    technique.
                  </p>

                  <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                    Notre architecture considère aussi les besoins de
                    maintenance futurs et les adaptations possibles de votre
                    machine. Nos conceptions mécaniques sont optimisées en
                    prévision de leur passage en production. De l&apos;usinage
                    traditionnel aux procédés les plus novateurs, nous
                    garantissons par ce biais l&apos;approvisionnement aisé de ces pièces.
                  </p>

                  <p className="is-size-7 has-text-left has-text-weight-semibold is-family-sans-serif mt-5">
                    <div className="buttons">
                      <a
                        href="#nous-contacter"
                        className="button is-light is-outlined"
                      >
                        Contacter un expert
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="column">
            <section className="hero is-halfheight banner-mechanical-and-hydraulic hero-mechanical-and-hydraulic">
              <div className="hero-body is-justify-content-center is-centered">
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}
